import './App.css';
import logo from './jon77.png';

function App() {
  return (
    <div className="app">
      <div className="w3-content content">
      <img src={logo} alt="jon77" className="logo" />
      <div className="w3-container w3-content w3-center w3-padding-64 container" id="band">
        <h2 className="w3-wide">INTRODUCTION TO JON77 LLC</h2>
        <p className="w3-justify m-bottom">Welcome to Jon77 LLC, your premier partner in social media management and advertising solutions. At Jon77, we specialize in curating and strategizing 
            impactful social media presence while harnessing the power of Google, Facebook, and TikTok advertising platforms. With a global team of experts, we ensure that your brand 
            receives the attention it deserves in the ever-evolving digital landscape.</p>

        <h2 className="w3-wide">OUR EXPERTISE</h2>
        <p className="w3-justify m-bottom">At Jon77 LLC, we understand the significance of maintaining a strong online presence. Our team comprises talented individuals from diverse backgrounds, 
                allowing us to offer a comprehensive suite of services tailored to meet your specific needs. Whether you're a budding startup or an established corporation, we have the 
                expertise to elevate your brand across various social media platforms.</p>

        <h2 className="w3-wide">SERVICES WE OFFER</h2>
        <div className="w3-row m-bottom">
          <div className="w3-third">
            <p><i><strong>Social Media Management: </strong></i></p>
            <p>Our team excels in crafting engaging content, managing interactions, and fostering meaningful connections with your audience across platforms such as Instagram, Twitter, 
                LinkedIn, and more.</p>
          </div>
          <div className="w3-third">
            <p><i><strong>Advertising Campaigns: </strong></i></p>
            <p>Leveraging advanced targeting strategies and creative ad formats, we design and execute effective advertising campaigns on Google, Facebook, and TikTok to maximize your ROI and drive conversions.</p>
          </div>
          <div className="w3-third">
            <p><i><strong>Global Expertise: </strong></i></p>
            <p>With professionals stationed around the globe, we possess a deep understanding of regional markets and cultural nuances, enabling us to devise localized strategies that resonate with your target audience worldwide.</p>
          </div>
        </div>

        <h2 className="w3-wide">WHY CHOOSE JON77 LLC</h2>
        <div className="w3-row m-bottom">
          <div className="w3-third">
            <p><i><strong>Tailored Solutions: </strong></i></p>
            <p>We believe in a personalized approach to every project, ensuring that our strategies align with your brand identity and objectives.</p>
          </div>
          <div className="w3-third">
            <p><i><strong>Proven Results: </strong></i></p>
            <p>Our track record speaks for itself. Through data-driven insights and continuous optimization, we consistently deliver tangible results that propel our clients towards success.</p>
          </div>
          <div className="w3-third">
            <p><i><strong>Dedicated Support: </strong></i></p>
            <p>From campaign ideation to post-analysis, our dedicated team is committed to providing unparalleled support at every stage of your journey.</p>
          </div>
        </div>

        <h2 className="w3-wide">GET IN TOUCH</h2>
        <p className="w3-justify">Ready to elevate your brand's presence in the digital realm? Contact Jon77 LLC today and let us help you unlock the full potential of social media and online advertising. Contact us through email <a href="mailto:info@jon77.com">info@jon77.com</a></p>
      </div>
    </div>

    <footer className="w3-container w3-padding-64 w3-center w3-opacity w3-light-grey w3-xlarge">
      <p className="w3-medium">&copy; 2024 jon77.com</p>
    </footer>
  </div>
  );
}

export default App;
